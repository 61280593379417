/* Velnati, Naga Mounika – 1002081064
Vaghela, Mrutyunjaysinh  – 1002078423
Vaghasia, Vatsal – 1002081754
Thombre, Harshada – 1002118901 */
body {
  /* background-image: url("/img/web-bg.jpeg"); */
  background-size: cover;
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
}
.font-loader {
  font-family: 'sans-serif';
}
footer {
  position: relative;
  width: 100%;
  bottom: 10px;
}
a:visited {
  text-decoration: none;
}
/* Margins */
.m-auto {
  margin: 0 auto;
}
.m-0 {
  margin: 0px;
}
.b-0 {
  bottom: 0px;
  position: relative;
}
.b-1{
  bottom: 0px;
  position: fixed;
}
.card-home {
  max-width: 100%;
  border-radius: 8px;
  padding: 10px;
  background-color: #ecf0f1;
  border: 1px solid #636e72;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
}
.submit-button-home {
  /* margin:0 auto; */
  margin-bottom: 20px;
}
.submit-home {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.submit-home:hover {
  background-color: #65647c;
}
.join-button {
  display: inline;
  text-align: center;
  width: 20px;
}
.line-hr-home {
  width: 30%;
  color: black;
}
.card-about {
  align-items: center;
  justify-content: center;
  max-width: 750px;
  margin: auto;
  background-color: #ecf0f1;
  border: 1px solid #636e72;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 20px;
}
.navbar1 {
  background-color: #333; /*DARK CHARCOAL*/
}
.company-name {
  float: right;
  padding-top: 10px;
  padding-right: 50px;
  color: #fff; /*WHITE*/
  font-size: 20px;
}
.link1 {
  color: black;
}
.link-header {
  color:whitesmoke;
}
.link:hover {
   color: #00F
}
/* -----------------------------------------CHAT------------------------------ */
/* CHAT FOOTER */
.footer-chat {
  background-color: #333;
  padding: 20px 0;
  width: 100%;
  bottom: 0;
}

/* ---------CHAT CONTAINER------------ */
.container-chat {
  display: flex;
  height: 100%;
  overflow-x: auto;
}
.child-container {
  /* max-width: 650px; */
  margin: 20px auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* -----------------------CHAT-SEND-RECEIVE------------------ */
.sender {
  /* margin-left: 200px; */
  background-color: #ccc;
  height: 80px;
  text-align: left;
}
.receiver {
  background-color: #ddd;
  height: 80px;
  text-align: right;
}
.time-left {
  float: left;
  color: #999;
  padding-bottom: 0;
}
.time-right {
  float: right;
  color: #aaa;
  padding-bottom: 0;
}
.chat-dashboard {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.input-msg-chat {
  height: 80px;
  width: 75%;
}
.button-submit {
  justify-content: center;
  background-color: #333;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.button-submit:hover {
  background-color: #65647c;
}
@media screen and (max-width: 768px) {
  .navbar1 {
    text-align: center;
  }
  .company-name {
    float: none;
    text-align: center;
  }
  .footer {
    position: relative;
    bottom: 0;
  }
  .container-admin {
    flex-direction: column;
  }

  .right-admin-card {
    max-width: 100%;
  }
  .footer-monitor {
    position: static;
  }
}
@media screen and (max-width: 600px) {
  .card-home {
    max-width: 100%;
    border-radius: 0;
  }
  .card-about {
    max-width: 100%;
    border-radius: 0;
  }
  .navbar1 {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .footer {
    position: relative;
    bottom: 0;
  }
  .company-name-footer {
    padding-top: 10px;
  }

  .line-hr-home {
    width: 50%;
  }
  .split-screen {
    flex-direction: column;
  }
  ul {
    flex-direction: column;
    padding-top: 10px;
  }
  li {
    margin-left: 0;
    margin-bottom: 5px;
  }
  .company-name {
    display: block;
    float: none;
    padding: 10px;
  }
  .sender,
  .receiver {
    margin-left: 0;
    width: 100%;
  }
  .input-msg-chat,
  .btn {
    margin-left: 0;
    width: 100%;
  }
}

/* Display */
.display-inline {
  display: inline;
}
.justify-content-center {
  justify-content: center;
}
.page-name {
  text-align: center;
}
.container-about {
  height: 100%;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .card-about {
    max-width: 100%;
    border-radius: 0;
  }
}
/* Navigation Bar */
.navbar {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* background-color: #007bff; */
  background-color: #333;
}
.nav-item {
  color: rgba(255, 255, 255, 0.5);
  font-size: small;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  display: inline;
}
.nav-item:hover {
  border-bottom: 4px solid white;
}
.nav-list {
  display: block;
  padding: 20px;
  margin: 0px;
  list-style-type: none;
}
.nav-item-links {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.company-title {
  padding: 0px;
  font-size: large;
  font-weight: 800;
  display: inline;
}
.text-color-1 {
  color: #34495e;
}
.font-italic {
  font-style: italic;
}
/* Text -alignment */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-no-decoration {
  text-decoration: none;
  color: inherit;
}
/* Paddings */
.pr-1 {
  padding-right: 10px;
}
.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}
.p-4 {
  padding: 40px;
}
.p-5 {
  padding: 50px;
}
.p-6 {
  padding: 60px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-6 {
  padding-top: 60px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-6 {
  padding-bottom: 60px;
}

/* Widths */
.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}
/* Navbar */
header {
  padding: 0px;
  margin: 0px;
}

/* Login and Signup */
.container {
  width: 90%;
  margin: 0 auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: #ecf0f1;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  font-size: small !important;
}
.ip {
  border: 1px solid #ecf0f1;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}
.ip-button {
  font-size: small;
  /* background-color: #3498db; */
  background-color: #333;
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}
.ip-button:hover {
  background: #4cd137;
  transition-duration: 0.5s;
}
.head-color {
  color: #34495e;
}
.company-name1 {
  float: right;
  padding-top: 10px;
  padding-right: 50px;
  color: #fff; /*WHITE*/
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .navbar1 {
    text-align: center;
  }
  .company-name1 {
    float: none;
    text-align: center;
  }
  .footer {
    position: relative;
    bottom: 0;
  }
  .container-admin {
    flex-direction: column;
  }

  .right-admin-card {
    max-width: 100%;
  }
  .footer-monitor {
    position: static;
  }
}
@media screen and (max-width: 600px) {
  .card-home {
    max-width: 100%;
    border-radius: 0;
  }
  .card-about {
    max-width: 100%;
    border-radius: 0;
  }
  .navbar1 {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .footer {
    position: relative;
    bottom: 0;
  }
  .company-name-footer {
    padding-top: 10px;
  }

  .line-hr-home {
    width: 50%;
  }
  .split-screen {
    flex-direction: column;
  }
  ul {
    flex-direction: column;
    padding-top: 10px;
  }
  li {
    margin-left: 0;
    margin-bottom: 5px;
  }
  .company-name1 {
    display: block;
    float: none;
    padding: 10px;
  }
  .sender,
  .receiver {
    margin-left: 0;
    width: 100%;
  }
  .input-msg-,
  .btn {
    margin-left: 0;
    width: 100%;
  }
  /* CARD */
  .card {
    max-width: 450px;
    margin: 20px auto;
    border-radius: 8px;
    background-color: #ecf0f1;
    border: 1px solid #636e72;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  }
  /* FORM */
  .card-form {
    padding: 20px;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  .form-menu {
    margin-bottom: 20px;
  }
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="message"],
  textarea {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  button:hover {
    background-color: #65647c;
  }
  .contact-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ecf0f1;
    border: 1px solid #636e72;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  }
  .contact-form {
    font-size: 14px;
  }
  .search-bar {
    max-width: 100%;
    border-radius: 0;
  }
}@media screen and (max-width: 400px) {
  table {
    font-size: 12px;
  }
  .footer {
    position: relative;
    bottom: 0;
  }
  .contact-form {
    font-size: 12px;
  }
}
.footer-home {
  background-color: #333;
  padding: 20px 0;
  width: 100%;
  bottom: 0;
  position: fixed;
}
.company-name-footer-home {
  font-size: 18px;
  color: #f2f2f2;
  margin-top: 0.3px;
  text-align: center;
}
.fa-home {
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  color: #f2f2f2;
  margin-top: 0.5px;
}

/* Footer */
.ft-container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #333;
  font-size: smaller;
  color: #ecf0f1;
  width: 100%;
  margin: 0 auto;
}
.ft-container a {
  color: white;
  text-decoration: none;
}

/* Dashboard */
/* Academia Dashboard */
.academia-dashboard {
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: #ecf0f1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.academia-card,
.academia-stats,
.dei-card,
.dei-stats {
  /* border:2px solid red; */
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: white;
  /* border:1px solid #D3D3D3; */
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-size: small !important;
}
.academia-stats,
.dei-stats {
  border-radius: 80%;
}
.academia-card:hover {
  transition-duration: 0.5s;
  border: 1px solid white;
  color: white;
  background-color: #3498db;
}

.academia-jobs,
.academia-candidate-profile {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: #ecf0f1;
}
.academic-job-list {
  gap: 10px;
  list-style: none;
  display: inline-flex;
}
.job-card {
  font-size: 12px;
}
.job-add {
  border: 1px solid salmon;
  color: salmon;
}
.job-add:hover {
  border: 1px solid salmon;
  color: white;
  background: salmon;
  transition-duration: 0.5s;
}
.job-update {
  border: 1px solid #f1c40f;
  color: #f1c40f;
}
.job-update:hover {
  border: 1px solid #f1c40f;
  color: white;
  background: #f1c40f;
  transition-duration: 0.5s;
}
.job-view {
  border: 1px solid #3498db;
  color: #3498db;
}
.job-view:hover {
  border: 1px solid #3498db;
  color: white;
  background: #3498db;
  transition-duration: 0.5s;
}
.job-delete {
  border: 1px solid red;
  color: red;
}
.job-delete:hover {
  border: 1px solid red;
  color: white;
  background: red;
  transition-duration: 0.5s;
}
.job-accept {
  border-radius: 5px;
  border: 1px solid #3498db;
  color: white;
  background-color: #3498db;
}
.job-accept:hover,
.job-reject:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.job-reject {
  border-radius: 5px;
  border: 1px solid red;
  color: white;
  background-color: red;
}

/* Academia About */
.academia-about {
  margin: 50px 0px 50px 0px;
  padding: 10px;
}
.about-list {
  list-style-type: upper-roman;
}
.table-faculty {
  border-radius: 10px;
  border-collapse: collapse;
}
.table-faculty tr:nth-child(2n) {
  background-color: #ecf0f1;
}
.table-faculty tr td,
.table-faculty thead th {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid black;
}
.thead-faculty {
  margin: 0px;
  padding: 0px;
  background: #34495e;
  color: white;
}
/* Responsive  */
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575.98px) {
  .company-title {
    padding: 0px;
    display: none;
  }
  .b-0 {
    position: relative;
  }
}

@media (max-width: 767.98px) {
  .b-0 {
    position: relative;
  }
  .col-sm-1 {
    width: 8.33%;
  }
  .col-sm-2 {
    width: 16.66%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33%;
  }
  .col-sm-5 {
    width: 41.66%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33%;
  }
  .col-sm-8 {
    width: 66.66%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33%;
  }
  .col-sm-11 {
    width: 91.66%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .col-md-1 {
    width: 8.33%;
  }
  .col-md-2 {
    width: 16.66%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33%;
  }
  .col-md-5 {
    width: 41.66%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33%;
  }
  .col-md-8 {
    width: 66.66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33%;
  }
  .col-md-11 {
    width: 91.66%;
  }
  .col-md-12 {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .col-lg-1 {
    width: 8.33%;
  }
  .col-lg-2 {
    width: 16.66%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33%;
  }
  .col-lg-5 {
    width: 41.66%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33%;
  }
  .col-lg-8 {
    width: 66.66%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33%;
  }
  .col-lg-11 {
    width: 91.66%;
  }
  .col-lg-12 {
    width: 100%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
}

/* Icon Size */

.fa-size {
  font-size: 25px;
}

/* Candidate Dashboard */
.candidate-container {
  border-radius: 10px;
  padding: 20px;
  /* border:1px solid rgba(0,0,0,0.6); */
  background-color: #ecf0f1;
  border: 1px solid #636e72;
}
